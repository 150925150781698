export interface IServices {
  cloudFunctions: string
  jobs: string
  jobsAutomation: string
  events: string
  registration: string
  applicationHistory: string
  content: string
  employer: string
  auth: string
  files: string
  messaging: string
  virtualDialogue: string
  notifications: string
  access: string
  sse: string
}

export interface IPlatformAdminServices {
  jobs: string
  jobsAutomation?: string
  auth: string
  user: string
  files: string
  registration: string
  customer: string
  notifications: string
}
export interface ICandidateServices extends IServices {
  candidate: string
  social: string
  community: string
  user: string
}
export interface IEmployerServices extends IServices {
  user: string
  jobsIntegration: string
  customer: string
  jobsAutomation: string
  candidatePool: string
}

export type ServiceNames = keyof (IServices & ICandidateServices & IEmployerServices)

export interface ITenantConfig {
  appConfigBaseURL: string
  defaultTenant: string
}

export interface IEnvironment {
  production: boolean
  env: Env
  firebaseConfig: any
  services: IServices

  authentication: boolean
  themesEnabled: boolean
  v2ReCaptcha: string
  tenantId: string
  cdnCname: string
  basePlatformHost: string
  algoliaAppId: string
  version: string
  passwordRecoveryEmailTemplateId: string
  emailVerificationEmailTemplateId: string
  betaMessageId: string
  tenant: ITenantConfig
}

export interface ICandidateEnvironment extends IEnvironment {
  services: ICandidateServices
  employerEmailActionRoute: string
  candidateEmailSuccessRoute: string
  candidateBaseUrl: string
  appDownloadDynamicLink: string
  smartBannerOptions: {
    appIcon: string
    appStoreUrl: string
    playStoreUrl: string
    ttl: string // this would keep banner closed for 24 hours
  }
}
export interface IEmployerEnvironment extends IEnvironment {
  services: IEmployerServices
  candidateExternalJobUrl: string
  employerBaseUrl: string
  careersPageBaseLink: string
  candidateEmailActionRoute: string
  employerEmailSuccessRoute: string
}

export interface ITalentEnvironment extends IEnvironment {
  services: IEmployerServices
  candidateExternalJobUrl: string
  careersPageBaseLink: string
  candidateEmailActionRoute: string
  employerEmailSuccessRoute: string
}

export interface IPlatformAdminEnvironment {
  production: boolean
  env: string
  firebaseConfig: any
  services: IPlatformAdminServices
  authentication: boolean
  themesEnabled: boolean
  v2ReCaptcha: string
  tenantId: string
  cdnCname: string
  algoliaAppId: string
  version: string
  basePlatformHost: string
}

export type Env = 'dev' | 'ci' | 'qa' | 'stage' | 'demo' | 'production'
export const PRODUCTION_ENV: 'production' = 'production'

export type ProjectId = 'cnect-dev' | 'cnect-ci' | 'cnect-qa-100' | 'cnect-stage-100' | 'cnect-demo-100' | 'cnect-prod-100'
export const envToProjectId: Record<Env, ProjectId> = {
  dev: 'cnect-dev',
  ci: 'cnect-ci',
  qa: 'cnect-qa-100',
  stage: 'cnect-stage-100',
  demo: 'cnect-demo-100',
  production: 'cnect-prod-100',
}
